import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAxios } from "../../utils/hook/useAxios";
import CreateSelectSubCategoryInput from "../CreateSelectSubCategoryInput";

function CategorySettingComponent({ webshopData, parentValue }) {
  const [webshop, setWebshop] = useState(webshopData.website);
  const [productImageUrl, setProductImageUrl] = useState(webshopData.logo);
  const [categories, setCategories] = useState([]);
  const [displayApprouve, setDisplayApprouve] = useState(false);
  const axios = useAxios();

  const [selectedCategories, setSelectedCategories] = useState([
    webshopData.category_1,
  ]);

  const [selectedCategoriesMsg, setSelectedCategoriesMsg] = useState([
    webshopData.category_1,
    webshopData.category_2,
    webshopData.category_3,
  ]);
  const [categoryCount, setCategoryCount] = useState(1);

  const { t } = useTranslation();
  const { lang, merchant_id } = useParams();

  useEffect(() => {
    setWebshop(webshopData.website);
    setProductImageUrl(webshopData.logo);
    setSelectedCategoriesMsg([
      webshopData.category_1,
      webshopData.category_2,
      webshopData.category_3,
    ]);
  }, [, parentValue]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCategories = async () => {
      const categoriesInfo = await Promise.all(
        selectedCategoriesMsg.map((categoryId) => {
          if (categoryId) {
            return fetch(
              `https://api.veritatrust.com/v1/data/categories/get-category-id/${categoryId}`
            )
              .then((response) => response.json())
              .catch((error) => {
                console.error("Error fetching category:", error);
                return null;
              });
          } else {
            return Promise.resolve(null);
          }
        })
      );

      // Filtrer les résultats pour éliminer les réponses null
      const filteredCategoriesInfo = categoriesInfo.filter(
        (categoryInfo) => categoryInfo !== null
      );

      setCategories(
        filteredCategoriesInfo.map((response) => response.category_name)
      );
    };

    fetchCategories();
  }, [, lang, selectedCategoriesMsg]);

  const handleSelectedCategory = (index, category) => {
    setSelectedCategories((prevSelectedCategories) => {
      const newSelectedCategories = [...prevSelectedCategories];
      newSelectedCategories[index] = category;
      return newSelectedCategories;
    });
    setSelectedCategoriesMsg((prevSelectedCategories) => {
      const newSelectedCategories = [...prevSelectedCategories];
      newSelectedCategories[index] = category;
      return newSelectedCategories;
    });
  };

  const addCategory = () => {
    if (categoryCount < 3) {
      setCategoryCount(categoryCount + 1);
      setSelectedCategories((prevSelectedCategories) => [
        ...prevSelectedCategories,
        "",
      ]);
      setSelectedCategoriesMsg((prevSelectedCategories) => [
        ...prevSelectedCategories,
        "",
      ]);
    }
  };

  const cancelCategory = () => {
    if (categoryCount > 1) {
      setCategoryCount(categoryCount - 1);
      setSelectedCategories((prevSelectedCategories) => {
        // Vérifier si la liste n'est pas déjà vide
        if (prevSelectedCategories.length === 0) {
          return prevSelectedCategories;
        }

        // Créer une nouvelle liste sans le dernier élément
        const updatedCategories = prevSelectedCategories.slice(0, -1);

        // Retourner la nouvelle liste
        return updatedCategories;
      });
      setSelectedCategoriesMsg((prevSelectedCategories) => {
        // Vérifier si la liste n'est pas déjà vide
        if (prevSelectedCategories.length === 0) {
          return prevSelectedCategories;
        }

        // Créer une nouvelle liste sans le dernier élément
        const updatedCategories = prevSelectedCategories.slice(0, -1);

        // Retourner la nouvelle liste
        return updatedCategories;
      });
    }
  };
  // const handleApprove = () => {
  //   const data = {
  //     status: "1",
  //     logo: productImageUrl,
  //   };

  //   // Add selected categories to the data object
  //   selectedCategories.forEach((category, index) => {
  //     data[`category_${index + 1}`] = category;
  //   });

  //   // Perform the update
  //   axios
  //     .put(`/merchant_profiles/update/${webshopData.id}`, data)
  //     .then(() => {
  //       onApproveChild();
  //       setDisplayApprouve(true);
  //     })
  //     .catch((error) => {
  //       console.error("Error updating webshop:", error);
  //     });
  // };

  const handleApprove = () => {
    const data = {
      logo: productImageUrl,
    };

    // Add selected categories to the data object
    selectedCategories.forEach((category, index) => {
      data[`category_${index + 1}`] = category;
    });

    // Perform the update
    fetch(
      `https://api.veritatrust.com/v1/merchant_profiles/update/${webshopData.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(() => {
        setDisplayApprouve(true);
      })
      .catch((error) => {
        console.error("Error updating webshop:", error);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md2 mb-3">
          {displayApprouve === true &&
            selectedCategories.map((category, index) => (
              <div key={index}>
                <p className="mb-1">Select Category {index + 1}</p>
                <select
                  className="form-select form-select-lg mb-3"
                  aria-label={`Category ${index + 1}`}
                  value={category}
                  onChange={(e) =>
                    handleSelectedCategory(index, e.target.value)
                  }
                >
                  <option value="1" data-category-id="1">
                    {t("b2b.SettingPage.AddReviewForBetaTesterCategoryAnimals")}
                  </option>
                  <option value="412" data-category-id="412">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryFoodsAndDrunk"
                    )}
                  </option>
                  <option value="141" data-category-id="141,222,2092">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryHighTech"
                    )}
                  </option>
                  <option value="436" data-category-id="436,536,642">
                    {t("b2b.SettingPage.AddReviewForBetaTesterCategoryHomeDIY")}
                  </option>
                  <option value="783" data-category-id="783">
                    {t("b2b.SettingPage.AddReviewForBetaTesterCategoryCulture")}
                  </option>
                  <option value="537" data-category-id="537">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryChilcare"
                    )}
                  </option>
                  <option value="111" data-category-id="111,922">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryCompanies"
                    )}
                  </option>
                  <option value="8" data-category-id="8,772,988">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategorySportLeisure"
                    )}
                  </option>
                  <option value="1239" data-category-id="1239">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryVideogames"
                    )}
                  </option>
                  <option value="888" data-category-id="888">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryVehicles"
                    )}
                  </option>
                  <option value="469" data-category-id="469">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryHealthBeauty"
                    )}
                  </option>
                  <option value="166" data-category-id="166,5181">
                    {t(
                      "b2b.SettingPage.AddReviewForBetaTesterCategoryFashionAccess"
                    )}
                  </option>
                </select>
                <CreateSelectSubCategoryInput
                  handleSelectSubCategory={(subCategory) => {
                    // Handle sub-category selection for the corresponding category
                  }}
                  category_parent_idSelected={category}
                />
              </div>
            ))}

          {categoryCount < 3 && displayApprouve === true && (
            <>
              <button
                className="btn btn-primary mb-2 btn-lg me-3"
                onClick={addCategory}
              >
                Add Category {categoryCount + 1}
              </button>
            </>
          )}
          {categoryCount > 1 && displayApprouve === true && (
            <button
              className="btn btn-danger mb-2 btn-lg"
              onClick={cancelCategory}
            >
              Cancel
            </button>
          )}
        </div>
        <div className="col-md7 mb-3">
          {displayApprouve == false && (
            <div className="mb-3">
              <p>
                <span className="badge text-bg-primary me-2">
                  {categories[0]}
                </span>

                <span className="badge text-bg-primary me-2">
                  {categories[1]}
                </span>

                <span className="badge text-bg-primary">{categories[2]}</span>
              </p>
            </div>
          )}

          <div className="col-md-3 mb-3">
            {displayApprouve === false && (
              <button
                className="btn btn-primary w-100 mb-2"
                onClick={() => {
                  setDisplayApprouve(true);
                }}
              >
                Edit
              </button>
            )}
            {displayApprouve === true && (
              <>
                <button
                  className="btn btn-danger w-100 mb-2"
                  onClick={() => {
                    setDisplayApprouve(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success w-100 mb-2"
                  onClick={handleApprove}
                >
                  Save Categories
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CategorySettingComponent;
