import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import star1 from "../../assets/star-1.svg";
import star2 from "../../assets/star-2.svg";
import star3 from "../../assets/star-3.svg";
import star4 from "../../assets/star-4.svg";
import star5 from "../../assets/star-5.svg";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ReviewComponentItem({ data, PropsresponseData, propsReviewType }) {
  const stars = [star1, star2, star3, star4, star5];
  const axios = useAxios();
  const [responseData, setResponseData] = useState([]);
  const [responseReview, setResponseReview] = useState("");

  const [showcaseAdded, setShowcaseAdded] = useState(data.addShowCase);

  const [addFavorite, setAddFavorite] = useState(data.favorite);

  const [showcase, setShowcase] = useState(
    data.addShowCase == "1" ? true : false
  );

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  const [favorite, setFavorite] = useState(data.favorite == "1" ? true : false);

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        setResponseData(PropsresponseData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchResponses();
    setShowcaseAdded(data.addShowCase);
    setAddFavorite(data.favorite);
  }, [data.id]);

  function handleAddshowcase() {
    const fetchResponses = async () => {
      try {
        if (propsReviewType === "0") {
          const response = await axios.get("/review/addshowcase/" + data.id);
          if (response.data[0].addShowCase === "1") setShowcaseAdded("0");
          else setShowcaseAdded("1");

          setShowcase(!showcase);
        } else {
          const response = await axios.get(
            "/productreview/addshowcase/" + data.id
          );
          if (response.data[0].addShowCase === "1") setShowcaseAdded("0");
          else setShowcaseAdded("1");

          setShowcase(!showcase);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchResponses();
  }

  function handleAddFavorite() {
    const fetchResponses = async () => {
      try {
        if (propsReviewType === "0") {
          const response = await axios.get("/review/favorite/" + data.id);

          if (response.data[0].favorite === "1") setAddFavorite("0");
          else setAddFavorite("1");

          setFavorite(!favorite);
        } else {
          const response = await axios.get(
            "/productreview/favorite/" + data.id
          );

          if (response.data[0].favorite === "1") setAddFavorite("0");
          else setAddFavorite("1");

          setFavorite(!favorite);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchResponses();
  }

  const handleSubmit = async (event) => {
    event.preventDefault(); // Empêche la soumission par défaut du formulaire

    const merchantuserData = await axios.get("/");

    try {
      // Effectuer la requête POST
      const dataSend = {
        ReviewId: data.id,
        ReviewType:
          propsReviewType === "0" ? "merchant_review" : "product_review",
        merchantUserId: merchantuserData.data.id,
        content: responseReview,
      };
      const res = await axios.post("/reviewResponse/response", dataSend);

      if (propsReviewType === "0") {
        if (res) await axios.get("/review/update/" + data.id);

        const response = await axios.get(
          "/reviewResponse/responses/" + data.id
        );

        if (response) setResponseData(response.data);
      } else {
        if (res) await axios.get("/productreview/update/" + data.id);

        const response = await axios.get(
          "/reviewResponse/responses/" + data.id
        );

        if (response) setResponseData(response.data);
      }

      console.log(res.data);
    } catch (error) {
      // Gérer les erreurs éventuelles
      console.error(error);
    }
  };
  const handleChange = (event) => {
    setResponseReview(event.target.value); // Mettre à jour la valeur de la réponse dans le state
  };

  return (
    <>
      <div className="reviews p-3 rounded-4 mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <img
            className="me-3"
            src={stars[data.rating - 1]}
            alt=""
            width="111"
          />
          <span
            className={
              favorite == false
                ? "material-symbols-sharp text-secondary me-3"
                : "material-symbols-sharp text-success me-3"
            }
            title="Add to favorite"
            onClick={handleAddFavorite}
          >
            favorite
          </span>
          <span
            className={
              showcase == false
                ? "material-symbols-sharp text-secondary"
                : "material-symbols-sharp text-success"
            }
            title="Add to showcase"
            onClick={handleAddshowcase}
          >
            widgets
          </span>
          <p className="lead ms-3 ">
            <span
              className={
                propsReviewType === "0"
                  ? "badge bg-primary"
                  : "badge bg-success"
              }
            >
              {propsReviewType === "0" ? "merchant review" : "product review"}
            </span>
          </p>
          <p className="mb-0 ms-auto">
            {moment(data.createdAt).format("MMM DD, YYYY")}{" "}
          </p>
        </div>
        <h3 className="h5">{data.title}</h3>
        <p>{data.content}</p>

        <p className="author mt-3">
          {t("b2b.ManagePage.by")}{" "}
          <a
            href={"https://api.veritatrust.com/users/" + data.userid}
            target="_blank"
            rel="noreferrer"
          >
            {data?.first_name?.replace("null", "")}
          </a>
        </p>
        <p>
          {t("b2b.ManagePage.Source")} :{" "}
          <span className="text-primary">
            {" "}
            {data.source === "1" ? "Invitation" : "Organic"}
          </span>
        </p>
        <p>
          {t("b2b.ManagePage.ReferenceNumber")} :{" "}
          <span className="text-primary">{data.order_id}</span>
        </p>
        <div className="d-flex align-items-start mt-3">
          <div
            className="nav flex-column nav-pills me-3"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-responses-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-responses"
              type="button"
              role="tab"
              aria-controls="v-pills-responses"
              aria-selected="true"
            >
              {t("b2b.ManagePage.Responses")}
            </button>
            <button
              className="nav-link"
              id="v-pills-report-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-report"
              type="button"
              role="tab"
              aria-controls="v-pills-report"
              aria-selected="false"
              tabindex="-1"
            >
              {t("b2b.ManagePage.Report")}
            </button>
          </div>
          <div className="tab-content w-100" id="v-pills-tabContent">
            <div
              className="tab-pane fade active show"
              id="v-pills-responses"
              role="tabpanel"
              aria-labelledby="v-pills-responses-tab"
              tabindex="0"
            >
              <form action="" onSubmit={handleSubmit}>
                <div className="d-md-flex gap-3 mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    rows="2"
                    placeholder="Your response"
                    value={responseReview}
                    onChange={handleChange}
                  ></textarea>
                  <button
                    type="submit"
                    className="btn btn-primary mt-2 mt-md-0"
                  >
                    {t("b2b.ManagePage.Submit")}
                  </button>
                </div>
              </form>

              <div className="responses mb-3">
                {responseData &&
                  responseData.map((response, index) => (
                    <div className="answer">
                      <div className="d-flex justify-content-between">
                        <p className="author">
                          by{" "}
                          <a href="#" target="_blank">
                            {response.fNmerchantUser} {response.lNmerchantUser}
                          </a>
                        </p>
                        <p>
                          {" "}
                          {moment(response.createdAt).format("MMM DD, YYYY")}
                        </p>
                      </div>
                      <p>{response.content}</p>
                    </div>
                  ))}
                <div className="my-2">
                  <a
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#answer123"
                    aria-expanded="false"
                    aria-controls="answer123"
                  >
                    {t("b2b.ManagePage.History")}
                  </a>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-report"
              role="tabpanel"
              aria-labelledby="v-pills-report-tab"
              tabindex="0"
            >
              <div className="d-md-flex gap-3">
                <textarea
                  className="form-control"
                  id="report"
                  rows="2"
                  placeholder="Your response"
                ></textarea>
                <button type="submit" className="btn btn-primary mt-2 mt-md-0">
                  {t("b2b.ManagePage.report")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewComponentItem;
